'use client'

import { Button } from '@/components'
import ImageError404 from './components/ImageError/ImageError'

import styles from './Page404.module.scss'

const Page404 = () => {
  return (
    <section className={styles.page404}>
      <ImageError404 />
      <h1 className={styles.page404__title}>Ops! Página não encontrada</h1>
      <p className={styles.page404__text}>
        Infelizmente, a página que você está procurando não foi encontrada. Por
        favor, tente novamente em alguns instantes.
      </p>

      <Button
        tagName="a"
        className={styles.page404__button}
        href="/"
        id="page-404-back-button"
        variant="primary"
      >
        Voltar
      </Button>
    </section>
  )
}

export default Page404
