import Image from 'next/image'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { If } from '@/components'

import Image404Container from '/public/illustrations/page404Background.svg'
import Image404Mobile from '/public/illustrations/page404-mobile.svg'
import Number4 from '/public/illustrations/4.svg'
import Hands from '/public/illustrations/hands-working.svg'
import Spark from '/public/illustrations/spark.svg'

import styles from './ImageError.module.scss'

const ImageError404 = () => {
  const isMobile = useMediaQuery('screen and (max-width: 767px)')

  return (
    <If
      condition={isMobile}
      renderIf={
        <Image src={Image404Mobile} alt="404 - Página não encontrada" />
      }
      renderElse={
        <div className={styles.image404}>
          <Image src={Image404Container} alt="404 - Página não encontrada" />
          <Image
            src={Number4}
            className={styles['image404__number-4']}
            alt="4"
          />
          <Image
            className={styles.image404__arms}
            src={Hands}
            alt="homem trabalhando"
          />
          <Image
            className={styles['image404__spark-1']}
            src={Spark}
            alt="faíscas"
          />
          <Image
            className={styles['image404__spark-2']}
            src={Spark}
            alt="faíscas"
          />
          <Image
            className={styles['image404__spark-3']}
            src={Spark}
            alt="faíscas"
          />
        </div>
      }
    />
  )
}
export default ImageError404
