'use client'

import { Suspense } from 'react'

import { Header, Footer } from '@/components'
import Page404 from '@/components/Page404/Page404'

const App404 = () => {
  return (
    <>
      <Suspense>
        <Header hiddenMenuDesktop={true} />
      </Suspense>
      <Page404 />
      <Footer />
    </>
  )
}

export default App404
